import { useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Title({ children, delay, animate = true, as, ...rest }) {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && animate) {
      controls.start("visible");
    }
  }, [inView, animate]);

  const Component = motion[as || "h1"];

  return (
    <Component
      ref={ref}
      initial={animate ? "hidden" : "visible"}
      animate={controls}
      variants={{
        visible: { y: 0, opacity: 1 },
        hidden: { y: "40%", opacity: 0 },
      }}
      transition={{ duration: 1, delay }}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Title;
