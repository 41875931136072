import { forwardRef } from "react";

import styled from "styled-components";

const Root = styled("span").withConfig({
  shouldForwardProp(prop, defaultValidatorFn) {
    return (
      !["color", "fontSize", "lineHeight", "fontWeight"].includes(prop) &&
      defaultValidatorFn(prop)
    );
  },
})((props) => {
  const {
    color,
    fontWeight,
    fontSize,
    lineHeight,
    maxWidth,
    block,
    theme,
  } = props;

  return {
    lineHeight,
    fontWeight,
    color: theme.colors[color] || color,
    fontSize: fontSize,
    maxWidth,
    display: block && "block",
  };
});

function Typography(props, ref) {
  const { children, variant, ...rest } = props;
  return (
    <Root {...variants[variant]} ref={ref} {...rest}>
      {children}
    </Root>
  );
}

const headings = {
  h2: {
    fontWeight: 600,
    fontSize: 54,
  },
  h3: {
    fontWeight: 600,
    fontSize: 46,
  },
  h4: {
    fontWeight: 600,
    fontSize: 42,
  },
};

const paragraphs = {
  p38s: {
    fontWeight: 600,
    fontSize: 38,
  },
  p34s: {
    fontWeight: 600,
    fontSize: 34,
  },
  p32s: {
    fontWeight: 600,
    fontSize: 32,
  },
  p26s: {
    fontWeight: 600,
    fontSize: 26,
  },
  p26r: {
    fontWeight: 400,
    fontSize: 26,
  },
  p24s: {
    fontWeight: 600,
    fontSize: 24,
  },
  p24m: {
    fontWeight: 500,
    fontSize: 24,
  },
  p22s: {
    fontWeight: 600,
    fontSize: 22,
  },
};

const body = {
  b22r: {
    fontWeight: 400,
    fontSize: 22,
  },
  b20s: {
    fontWeight: 600,
    fontSize: 20,
  },
  b20r: {
    fontWeight: 400,
    fontSize: 20,
  },

  b18s: {
    fontWeight: 600,
    fontSize: 18,
  },
  b18m: {
    fontWeight: 500,
    fontSize: 18,
  },
  b18r: {
    fontWeight: 400,
    fontSize: 18,
  },

  b16s: {
    fontWeight: 600,
    fontSize: 16,
  },
  b16m: {
    fontWeight: 500,
    fontSize: 16,
  },
  b16r: {
    fontWeight: 400,
    fontSize: 16,
  },

  b14m: {
    fontWeight: 500,
    fontSize: 14,
  },
  b14r: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4,
  },

  b12r: {
    fontWeight: 400,
    fontSize: 14,
  },
};

const variants = {
  ...headings,
  ...paragraphs,
  ...body,
};

export default forwardRef(Typography);
